import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircle from "@mui/icons-material/AccountCircle";
import isLoggedIn from "../SignIn/isLoggedIn";
import { getFormCategoryList } from "../../datasource/attr";
import { getFormTypeRights } from "../../datasource/form";
import FormCategory from "./FormCategory";
import ErrorFallback from "../ErrorFallback";
import useMobileView from "../../hooks/useMobileView";

const store = require("store");

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  minHeight: "40px",
  [theme.breakpoints.down("sm")]: {
    minHeight: "32px",
  },
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

const Main = ({
  children,
  formTypeRights,
  formCategoryList,
  setFormTypeRights,
  setFormCategoryList,
  setAppLoadingStatus,
  setAppLoadingText,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  const navigate = useNavigate();
  const isMobileView = useMobileView();

  useEffect(() => {
    const _getFormCategoryList = async () => {
      setAppLoadingText("Loading");
      await getFormCategoryList()
        .then((fc) => {
          setFormCategoryList(fc.data);
        })
        .catch((error) => {
          console.log(error);
          if (!error.message.includes("status code 401")) {
            setFormCategoryList([]);
            setFetchError(error);
            setAppLoadingStatus(false);
          }
        });
    };

    const _getFormTypeRights = async () => {
      setAppLoadingText("Checking");
      await getFormTypeRights()
        .then((fc) => {
          setFormTypeRights(fc.data);
        })
        .catch((error) => {
          console.log(error);
          if (!error.message.includes("status code 401")) {
            setFormTypeRights([]);
            setFetchError(error);
            setAppLoadingStatus(false);
          }
        });
    };

    const fetchData = async () => {
      await _getFormCategoryList();
      await _getFormTypeRights();
    };
    fetchData()
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigate = (targetRoute) => {
    handleDrawerClose();
    navigate(targetRoute);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        open={open}
        sx={(theme) => ({ backgroundColor: theme.palette.primary.light })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={(theme) => ({
              marginRight: 5,
              ...(open && { display: "none" }),
              [theme.breakpoints.down("sm")]: {
                marginRight: 1,
              },
            })}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={(theme) => ({
                flex: 1,
                cursor: "pointer",
                letterSpacing: 2,
                [theme.breakpoints.down("sm")]: {
                  letterSpacing: 1,
                  textAlign: "center",
                },
              })}
              onClick={() => handleNavigate("/")}
            >
              E-Form System
            </Typography>
            {isLoggedIn() && (
              <div>
                {!isMobileView && (
                  <Box
                    sx={(theme) => ({
                      display: "inline-flex",
                      fontWeight: 500,
                      letterSpacing: "0.02857em",
                      textTransform: "uppercase",
                      padding: "8px 11px",
                      borderRadius: "4px",
                      alignItems: "center",
                      [theme.breakpoints.down("sm")]: {
                        minWidth: 0,
                        p: 0,
                      },
                    })}
                  >
                    <AccountCircle
                      sx={(theme) => ({
                        mr:
                          (store.get("fullname_eng") ||
                            store.get("fullname_chi")) &&
                          1,
                        [theme.breakpoints.down("sm")]: {
                          mr: 0,
                        },
                      })}
                    />

                    <Typography
                      variant="subtitle1"
                      noWrap
                      component="div"
                      sx={{
                        fontSize: "0.875rem",
                        whiteSpace: "pre-line",
                        lineHeight: 1.25,
                      }}
                    >
                      {store.get("fullname_eng")}
                      {store.get("fullname_chi") && (
                        <>
                          <br />
                          {store.get("fullname_chi")}
                        </>
                      )}
                    </Typography>
                  </Box>
                )}
                {isMobileView && (
                  <>
                    <Button
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                      sx={(theme) => ({
                        [theme.breakpoints.down("sm")]: {
                          minWidth: 0,
                          p: 0,
                        },
                      })}
                    >
                      <AccountCircle
                        sx={(theme) => ({
                          mr:
                            (store.get("fullname_eng") ||
                              store.get("fullname_chi")) &&
                            1,
                          [theme.breakpoints.down("sm")]: {
                            mr: 0,
                          },
                        })}
                      />
                    </Button>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem disabled>
                        {store.get("fullname_eng")}
                        {store.get("fullname_chi") && (
                          <>
                            <br />
                            {store.get("fullname_chi")}
                          </>
                        )}
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {formCategoryList !== null && formTypeRights !== null && (
        <>
          <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
            <Box
              sx={{ width: 330 }}
              role="presentation"
              onKeyDown={handleDrawerClose}
            >
              <List
                sx={(theme) => ({
                  py: 0,
                  minHeight: 64,
                  [theme.breakpoints.down("sm")]: { minHeight: 56 },
                })}
              >
                <ListItemButton
                  sx={{
                    minHeight: "inherit",
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleNavigate("/")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <DashboardIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={"電子表格總覽"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </List>

              <Divider />

              <List sx={{ py: 0, height: "100%" }}>
                {formCategoryList.map((obj, index) => (
                  <FormCategory
                    formTypeRights={formTypeRights}
                    formCategory={obj}
                    key={index}
                    drawerOpen={open}
                    handleNavigate={handleNavigate}
                  />
                ))}
              </List>
            </Box>
          </Drawer>

          <Box
            component="main"
            sx={(theme) => ({
              flexGrow: 1,
              p: 3,
              [theme.breakpoints.down("sm")]: { px: 2 },
            })}
          >
            <DrawerHeader />
            {fetchError !== null ? (
              <ErrorFallback error={fetchError} />
            ) : (
              children
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
export default Main;
