import React, {Fragment, useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

// Custom Component
import Alert from "../Alert";
import Loader from "../Loader";
// import SignOut from "./SignOut";

// Fetch Data
import {oidcSignIn, getUserData} from "../../datasource/signIn";

const store = require("store");

const OidcCallback = () => {
    const {search} = useLocation();

    // Get URL query Paramater
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get("code");
    const error = searchParams.get("error");
    const urlParams = new URLSearchParams(window.location.search);
    const searchParamString = urlParams.toString();

    // Alert Define
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertData, setAlertData] = useState({
        type: "",
        title: "",
        content: "",
    });
    const [loggingIn, setLoggingIn] = useState(null);

    // Build Alert
    const buildAlert = (data) => {
        setAlertData({
            type: data.type,
            title: data.title,
            content: data.content,
        });
        setAlertOpen(true);
    };

    const saveLoginInfo = async (oidcResult, userData) => {
        store.set("locale", 10001);
        store.set("company", userData.data.company);
        store.set("email", userData.data.email);
        store.set("fullname_chi", userData.data.fullname_chi);
        store.set("fullname_eng", userData.data.fullname_eng);
        store.set("section", userData.data.section);
        store.set("user_seq", userData.data.user_seq);
        store.set("username", userData.data.username);
        store.set("access_token", oidcResult.data.access_token);
        store.set("refresh_token", oidcResult.data.refresh_token);
        store.set("loggedIn", true);

    };

    useEffect(() => {
        const handleOidcSignIn = async () => {
            if (code) {
                setLoggingIn(true);
                await oidcSignIn(searchParamString)
                    .then((oidcResult) => {
                        getUserData(
                            oidcResult.data.token_type + " " + oidcResult.data.access_token
                        )
                            .then((userData) => {
                                saveLoginInfo(oidcResult, userData).then(() => {
                                    window.location.href = "/";
                                });
                            })
                            .catch((err) => {
                                buildAlert({
                                    // Alert Type: success, info, warning, error
                                    type: "error",
                                    title: "錯誤提示",
                                    content: err.message,
                                });
                            });
                    })
                    .catch((err) => {
                        buildAlert({
                            // Alert Type: success, info, warning, error
                            type: "error",
                            title: "錯誤提示",
                            content: err.message,
                        });
                    });
                setLoggingIn(false);
            } else if (error) {
                setTimeout(() => {
                    window.location.href = "/signIn";
                }, 2000);

                buildAlert({
                    // Alert Type: success, info, warning, error
                    type: "error",
                    title: "錯誤提示",
                    content: error,
                });
            }
        };

        handleOidcSignIn();
    }, []);

    if (loggingIn) return <Loader fullPage/>;
    return (
        // console.log("OIDC Code | Variable: code (Callback.js)"),
        // console.log(code),

        // console.log("OIDC Error | Variable: error (Callback.js)"),
        // console.log(error),

        // console.log("---------------------------------------------------------------------------------"),

        <Fragment>
            <div>
                <Alert
                    open={alertOpen}
                    alertType={alertData.type}
                    alertTitle={alertData.title}
                    alertContent={alertData.content}
                    handleClose={() => setAlertOpen(false)}
                />
            </div>
        </Fragment>
    );
};

export default OidcCallback;
