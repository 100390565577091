import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PanToolIcon from "@mui/icons-material/PanTool";

const AccessDenied = () => {
  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
      }}
      direction="column"
      spacing={3}
    >
      <PanToolIcon sx={{ fontSize: "5rem", color: "#fb6060" }} />
      <Typography
        component="h1"
        variant="h5"
        align="center"
        sx={{ whiteSpace: "pre-line" }}
      >
        Access Denied
      </Typography>
    </Stack>
  );
};

export default AccessDenied;
