import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import SyncIcon from "@mui/icons-material/Sync";
// import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  getDataSyncSectionName,
  getCreateRoute,
  getSyncRoute,
  // getAdminRoute,
} from "./formCategorySwitch";

const FormListItem = ({ rights, handleNavigate, drawerOpen, item }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <ListItem component={Box} disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: drawerOpen ? "initial" : "center",
            px: 2.5,
          }}
          onClick={handleClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: drawerOpen ? 3 * 2 : "auto",
              justifyContent: "center",
            }}
          ></ListItemIcon>
          <ListItemText
            primary={item.form_name}
            sx={{ opacity: drawerOpen ? 1 : 0 }}
          />
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" sx={{ py: 0, minHeight: 48 }}>
            <ListItemButton
              sx={{ py: 0, minHeight: "inherit" }}
              onClick={() => handleNavigate(getCreateRoute(item.form_type_seq))}
              disabled={rights.create_right !== "Y"}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 0.5,
                  pl: 3 * 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TurnLeftIcon sx={{ transform: "rotate(180deg)", mr: 0.5 }} />
                <NoteAddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={"建立"} sx={{ mb: 0 }} />
            </ListItemButton>
          </List>
          {rights.data_sync_right === "Y" && item.form_type_seq === 3 && (
            <List component="div">
              <ListItemButton
                sx={{ py: 0 }}
                onClick={() => handleNavigate(getSyncRoute(item.form_type_seq))}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 0.5,
                    pl: 3 * 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TurnLeftIcon sx={{ transform: "rotate(180deg)", mr: 0.5 }} />
                  <SyncIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={getDataSyncSectionName(item.form_code)}
                  sx={{ mb: 0 }}
                />
              </ListItemButton>
            </List>
          )}
          {/* {formTypeAdminRight.isAdmin === "Y" && (
            <List component="div">
              <ListItemButton
                sx={{ py: 0 }}
                onClick={() =>
                  handleNavigate(getAdminRoute(item.form_type_seq))
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 0.5,
                    pl: 3 * 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TurnLeftIcon sx={{ transform: "rotate(180deg)", mr: 0.5 }} />
                  <AdminPanelSettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={"特別處理"} sx={{ mb: 0 }} />
              </ListItemButton>
            </List>
          )} */}
        </Collapse>
      </ListItem>
    </Box>
  );
};

export default FormListItem;
