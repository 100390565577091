import React, { Fragment } from "react";
import NotFound from "../../components/NotFound";

const NotFoundView = () => {
  return (
    <Fragment>
      <NotFound />
    </Fragment>
  );
};

export default NotFoundView;
