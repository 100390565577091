import React, {Fragment, useState, useEffect} from "react";
import {Outlet} from "react-router-dom";
import CustomBackdrop from "../../components/CustomBackdrop";

import Main from "../../components/Main";

const store = require("store");

const MainView = () => {
    const [formTypeRights, setFormTypeRights] = useState(null);
    const [formCategoryList, setFormCategoryList] = useState(null);
    const [appLoadingStatus, setAppLoadingStatus] = useState(true);
    const [appLoadingText, setAppLoadingText] = useState("");


    useEffect(() => {
        if (store.get("access_token") === undefined) {
            window.location.href = process.env.REACT_APP_OIDC_LOGIN_URL;
        }
    }, []);

    return (
        <Fragment>
            {appLoadingStatus && <CustomBackdrop text={appLoadingText}/>}
            <Main
                formTypeRights={formTypeRights}
                formCategoryList={formCategoryList}
                setFormTypeRights={setFormTypeRights}
                setFormCategoryList={setFormCategoryList}
                setAppLoadingStatus={setAppLoadingStatus}
                setAppLoadingText={setAppLoadingText}
            >
                <Outlet
                    context={{
                        formTypeControl: {formTypeRights},
                        formCategory: {formCategoryList},
                        appLoading: {
                            appLoadingStatus,
                            setAppLoadingStatus,
                            setAppLoadingText,
                        },
                    }}
                />
            </Main>
        </Fragment>
    );
};

export default MainView;
