const store = require("store");

const SignOut = () => {
  store.remove("locale");
  store.remove("company");
  store.remove("email");
  store.remove("fullname_chi");
  store.remove("fullname_eng");
  store.remove("section");
  store.remove("user_seq");
  store.remove("username");
  store.remove("access_token");
  store.remove("refresh_token");
  store.remove("loggedIn");

  store.remove("grade_code");
  store.remove("employee_id");

  console.log("Sign Out Successful");

  window.location.reload();
};

export default SignOut;
