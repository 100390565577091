import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const NotFound = () => {
  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
      }}
      direction="column"
      spacing={5}
    >
      <QuestionMarkIcon sx={{ fontSize: "5rem", color: "#25ccbf" }} />
      <Typography
        component="h1"
        variant="h5"
        align="center"
        sx={{ whiteSpace: "pre-line" }}
      >
        404 | This page could not be found
      </Typography>
    </Stack>
  );
};

export default NotFound;
