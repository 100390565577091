import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getUserInfo} from "../../datasource/attr";

// Custom Component
import Alert from "../Alert";
import Loader from "../Loader";

const SignIn = () => {

    const store = require("store");

    const {username} = useParams();

    // Alert Define
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertData, setAlertData] = useState({
        type: "",
        title: "",
        content: "",
    });
    const [loggingIn, setLoggingIn] = useState(null);

    // Get URL query Paramater
    // store selected username
    store.set("_username", username);

    // Build Alert
    const buildAlert = (data) => {
        setAlertData({
            type: data.type,
            title: data.title,
            content: data.content,
        });
        setAlertOpen(true);
    };

    useEffect(() => {
        const oidcInternalSignInAction = () => {
            store.set("historical_redirection_url", window.location.href)
            window.location.href = process.env.REACT_APP_OIDC_LOGIN_URL;
        };

        const handleUserInfo = async () => {
            setLoggingIn(true);
            await getUserInfo()
                .then((res) => {
                    if (res.data.length > 0) {
                        store.set("grade_code", res.data[0].grade_code);
                        store.set("employee_id", res.data[0].employee_id);
                    } else {
                        store.set("grade_code", null);
                        store.set("employee_id", null);
                    }
                    let historical_redirection_url = store.get("historical_redirection_url");
                    if (!historical_redirection_url) {
                        historical_redirection_url = "/";
                    }else {
                        store.remove("historical_redirection_url")
                    }
                    console.log("historical_redirection_url: " + historical_redirection_url);
                    window.location.href = historical_redirection_url;

                })
                .catch((error) => {
                    buildAlert({
                        // Alert Type: success, info, warning, error
                        type: "error",
                        title: "錯誤提示",
                        content: `${error.message}`,
                    });
                });
            setLoggingIn(false);
        };

        if (store.get("access_token") === undefined) {
            oidcInternalSignInAction();
        } else if (store.get("grade_code") === undefined) {
            handleUserInfo();
        }
    }, []);
    if (loggingIn) return <Loader fullPage/>;
    return (
        <>
            <Alert
                open={alertOpen}
                alertType={alertData.type}
                alertTitle={alertData.title}
                alertContent={alertData.content}
                handleClose={() => setAlertOpen(false)}
            />
        </>
    );
};

export default SignIn;
