import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SentimentVeryDissatisfiedRoundedIcon from "@mui/icons-material/SentimentVeryDissatisfiedRounded";

const ErrorFallback = ({ error }) => {
  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
      }}
      direction="column"
      spacing={3}
    >
      <SentimentVeryDissatisfiedRoundedIcon
        sx={{ fontSize: "5rem", color: "#25ccbf" }}
      />
      <Typography
        component="h1"
        variant="h5"
        align="center"
        sx={{ fontFamily: "monospace" }}
      >
        Something went wrong
      </Typography>
      <Box
        component="pre"
        sx={{ whiteSpace: "pre-line", overflowWrap: "break-word" }}
      >
        {`Error: ${error.message.split(": ").slice(-1).toString()}`}
      </Box>
      <Button
        onClick={() => {
          window.location.reload();
        }}
        variant="contained"
        size="medium"
      >
        Reload Page
      </Button>
    </Stack>
  );
};

export default ErrorFallback;
