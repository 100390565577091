import React, { useEffect, useMemo } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import NotFoundView from "../views/NotFound/NotFoundView";
import AccessDenied from "../components/AccessDenied";
import NoRight from "../components/NoRight/NoRight";

const Dashboard = ({ children }) => {
  const { appLoading } = useOutletContext();
  const { setAppLoadingStatus } = appLoading;

  useEffect(() => {
    setAppLoadingStatus(false);
  }, [setAppLoadingStatus]);

  return <>{children}</>;
};

const NotFound = ({ children }) => {
  const { appLoading } = useOutletContext();
  const { setAppLoadingStatus } = appLoading;

  useEffect(() => {
    setAppLoadingStatus(false);
  }, [setAppLoadingStatus]);

  return <>{children}</>;
};

const NewForm = ({ children }) => {
  let params = useParams();
  let form_type_seq_match = params.form_type_seq.match(/^\d+$/);

  const { formTypeControl, appLoading } = useOutletContext();
  const { formTypeRights } = formTypeControl;
  const { setAppLoadingStatus } = appLoading;

  useEffect(() => {
    setAppLoadingStatus(false);
  }, [setAppLoadingStatus]);

  const rights = useMemo(() => {
    return formTypeRights.find(
      (r) => Number(r.form_type_seq) === Number(params.form_type_seq)
    );
  }, [formTypeRights, params.form_type_seq]);

  if (!form_type_seq_match || !rights) {
    return <NotFoundView />;
  }

  if (rights && rights.form_type_access_right === "N") {
    return <AccessDenied />;
  }
  if (rights && rights.create_right === "N") {
    return (
      <NoRight message={`${rights.form_name}\nYou have no create right`} />
    );
  }

  return <>{children}</>;
};

const ExistForm = ({ children }) => {
  let params = useParams();
  let form_type_seq_match = params.form_type_seq.match(/^\d+$/);
  let form_seq_match = params.form_seq.match(/^\d+$/);

  const { formTypeControl, appLoading } = useOutletContext();
  const { formTypeRights } = formTypeControl;
  const { setAppLoadingStatus } = appLoading;

  useEffect(() => {
    setAppLoadingStatus(false);
  }, [setAppLoadingStatus]);

  const rights = useMemo(() => {
    return formTypeRights.find(
      (r) => Number(r.form_type_seq) === Number(params.form_type_seq)
    );
  }, [formTypeRights, params.form_type_seq]);

  if (!form_type_seq_match || !form_seq_match || !rights) {
    return <NotFoundView />;
  }

  if (rights && rights.form_type_access_right === "N") {
    return <AccessDenied />;
  }

  return <>{children}</>;
};

const DataSync = ({ children }) => {
  let params = useParams();
  let form_type_seq_match = params.form_type_seq.match(/^\d+$/);

  const { formTypeControl, appLoading } = useOutletContext();
  const { formTypeRights } = formTypeControl;
  const { setAppLoadingStatus } = appLoading;

  useEffect(() => {
    setAppLoadingStatus(false);
  }, [setAppLoadingStatus]);

  const rights = useMemo(() => {
    return formTypeRights.find(
      (r) => Number(r.form_type_seq) === Number(params.form_type_seq)
    );
  }, [formTypeRights, params.form_type_seq]);

  // Petty Cash Data Synchronization Handling
  if (Number(rights.form_type_seq) !== 3) {
    return <NotFoundView />;
  }
  // Petty Cash Data Synchronization Handling

  if (!form_type_seq_match || !rights) {
    return <NotFoundView />;
  }

  if (rights && rights.form_type_access_right === "N") {
    return <AccessDenied />;
  }
  if (rights && rights.data_sync_right === "N") {
    return (
      <NoRight
        message={`${rights.form_name}\nYou have no right to synchronize data`}
      />
    );
  }

  return <>{children}</>;
};

const ValidateRoute = {
  Dashboard,
  NewForm,
  ExistForm,
  DataSync,
  NotFound,
};
export default ValidateRoute;
