import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./Loader.css";

const Loader = ({ fullPage = false, size = 100, padding = 10 }) => {
  return (
    <div
      className={fullPage ? "loader-container-full-page" : "loader-container"}
    >
      <div style={{ padding: padding }}>
        <CircularProgress size={size} disableShrink />
      </div>
    </div>
  );
};

export default Loader;
