import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { getCategoryIcon } from "./formCategorySwitch";
import FormListItem from "./FormListItem";

const FormCategory = ({
  formTypeRights,
  formCategory,
  drawerOpen,
  handleNavigate,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        disablePadding
        sx={(theme) => ({
          display: "block",
          minHeight: 64,
          [theme.breakpoints.down("sm")]: { minHeight: 48 },
        })}
      >
        <ListItemButton
          sx={{
            minHeight: "inherit",
            justifyContent: drawerOpen ? "initial" : "center",
            px: 2.5,
          }}
          onClick={handleClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: drawerOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {getCategoryIcon(formCategory.category_seq)}
          </ListItemIcon>
          <ListItemText
            primary={formCategory.category_name}
            sx={(theme) => ({
              opacity: drawerOpen ? 1 : 0,
              "&::after": {
                width: 160,
                display: "block",
                borderBottomColor: theme.palette.primary.main,
                borderBottomStyle: "solid",
                borderBottomWidth: 3,
                content: '""',
                transform: open ? "scaleX(1)" : "scaleX(0)",
                transition: "transform 250ms ease-in-out",
                transformOrigin: "0% 50%",
              },
            })}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{ ".MuiCollapse-wrapperInner": { height: "100%" } }}
        >
          {formCategory.child.map((c, i) => {
            let rights = formTypeRights.find(
              (r) => r.form_type_seq === c.form_type_seq
            );
            if (rights === undefined) {
              rights = {};
            }
            return (
              <FormListItem
                rights={rights}
                handleNavigate={handleNavigate}
                drawerOpen={drawerOpen}
                item={c}
                key={i}
              />
            );
          })}
        </Collapse>
      </ListItem>
    </>
  );
};

export default FormCategory;
