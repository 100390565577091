import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BlockIcon from "@mui/icons-material/Block";

const NoRight = ({ message }) => {
  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
      }}
      direction="column"
      spacing={3}
    >
      <BlockIcon
        sx={(theme) => ({ fontSize: "5rem", color: theme.palette.error.light })}
      />
      <Typography
        component="h1"
        variant="h5"
        align="center"
        sx={{ whiteSpace: "pre-line" }}
      >
        {message}
      </Typography>
    </Stack>
  );
};

export default NoRight;
