import axios from "axios";
import SignOut from "../components/SignIn/SignOut";

const store = require("store");

// FW Form
const getOtList = async () => {
  const attr_api = "/attr";
  const attr_name = "/otList";

  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url: process.env.REACT_APP_API_URL_3001 + attr_api + attr_name,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};
const getReasonList = async () => {
  const attr_api = "/attr";
  const attr_name = "/reasonList";

  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url: process.env.REACT_APP_API_URL_3001 + attr_api + attr_name,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};

// FA Form
const getAmHourList = async () => {
  const attr_api = "/attr";
  const attr_name = "/amHourList";
  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url: process.env.REACT_APP_API_URL_3001 + attr_api + attr_name,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};
const getNoonHourList = async () => {
  const attr_api = "/attr";
  const attr_name = "/noonHourList";
  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url: process.env.REACT_APP_API_URL_3001 + attr_api + attr_name,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};
const getDinnerAllowanceList = async () => {
  const attr_api = "/attr";
  const attr_name = "/dinnerAllowanceList";
  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url: process.env.REACT_APP_API_URL_3001 + attr_api + attr_name,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};
const getSupperAllowanceList = async () => {
  const attr_api = "/attr";
  const attr_name = "/supperAllowanceList";
  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url: process.env.REACT_APP_API_URL_3001 + attr_api + attr_name,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};

// General
const getFormCategoryList = async () => {
  const attr_api = "/attr";
  const attr_name = "/formCategoryList";

  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url: process.env.REACT_APP_API_URL_3001 + attr_api + attr_name,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
        throw new Error(error);
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};

const getUserInfo = async () => {
  const attr_api = "/attr";
  const attr_name = "/userInfo";

  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url: process.env.REACT_APP_API_URL_3001 + attr_api + attr_name,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};

const getEmployeeMasterList = async (form_type_seq) => {
  const attr_api = "/attr";
  const attr_name = "/employeeMasterList";

  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url:
        process.env.REACT_APP_API_URL_3001 +
        attr_api +
        attr_name +
        "/" +
        form_type_seq,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};

const getChooseUserRight = async (form_type_seq) => {
  const attr_api = "/attr";
  const attr_name = "/chooseUserRight";

  let result;
  try {
    let headers = {
      Authorization: "Bearer " + store.get("access_token"),
    };

    result = await axios({
      method: "get",
      url:
        process.env.REACT_APP_API_URL_3001 +
        attr_api +
        attr_name +
        "/" +
        form_type_seq,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        SignOut();
      } else {
        throw new Error(error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};

export {
  // FW
  getOtList,
  getReasonList,
  // FA
  getAmHourList,
  getNoonHourList,
  getDinnerAllowanceList,
  getSupperAllowanceList,
  // General
  getFormCategoryList,
  getUserInfo,
  getChooseUserRight,
  getEmployeeMasterList,
};
