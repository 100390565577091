import axios from "axios";
import SignOut from "../../components/SignIn/SignOut";

const store = require("store");

const postApproveForm = async (params) => {
    const form_api = "/form/approve";
    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL_3001 + form_api,
            headers: headers,
            data: params,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};
const postReadForm = async (params) => {
    const form_api = "/form/read";
    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL_3001 + form_api,
            headers: headers,
            data: params,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};
const postRejectForm = async (params) => {
    const form_api = "/form/reject";
    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL_3001 + form_api,
            headers: headers,
            data: params,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};
const postReturnForm = async (params) => {
    const form_api = "/form/return";
    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL_3001 + form_api,
            headers: headers,
            data: params,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};
const postCancelForm = async (params) => {
    const form_api = "/form/cancel";
    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL_3001 + form_api,
            headers: headers,
            data: params,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};
const postRfiForm = async (params) => {
    const form_api = "/form/rfi";
    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL_3001 + form_api,
            headers: headers,
            data: params,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};

const getRfiUserList = async (form_seq) => {
    const form_api = "/form/rfiUserList";

    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "get",
            url: process.env.REACT_APP_API_URL_3001 + form_api + "/" + form_seq,
            headers: headers,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};

const postRfiCompleteForm = async (params) => {
    const form_api = "/form/rfi/complete";
    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL_3001 + form_api,
            headers: headers,
            data: params,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};

const getDashboardForm = async (params) => {
    const form_api = `/form/dashboardForm/${params.pagination.page}/${params.pagination.pageSize}`;

    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL_3001 + form_api,
            headers: headers,
            data: params.filter
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};

const getFormAccessRight = async (form_type_seq, form_seq) => {
    const form_api = "/form/accessRight";
    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "get",
            url:
                process.env.REACT_APP_API_URL_3001 +
                form_api +
                "/" +
                form_type_seq +
                "/" +
                form_seq,
            headers: headers,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};

const getFormTypeRights = async () => {
    const form_api = "/form/formTypeRights";
    let result;
    try {
        let headers = {
            Authorization: "Bearer " + store.get("access_token"),
        };

        result = await axios({
            method: "get",
            url: process.env.REACT_APP_API_URL_3001 + form_api,
            headers: headers,
        });

        return result.data;
    } catch (error) {
        try {
            if (error.response.status === 401) {
                SignOut();
            } else {
                throw new Error(error);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
};

export {
    postApproveForm,
    postReadForm,
    postRejectForm,
    postReturnForm,
    postCancelForm,
    postRfiForm,
    getRfiUserList,
    postRfiCompleteForm,
    getDashboardForm,
    getFormAccessRight,
    getFormTypeRights,
};
