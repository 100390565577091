import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green, red, teal } from "@mui/material/colors";

import AuthNavigator from "./navigation/AuthNavigator";
import MainNavigator from "./navigation/MainNavigator";
import isLogginIn from "./components/SignIn/isLoggedIn";

const theme = createTheme({
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          lineHeight: 1,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: teal[400],
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Bahnschrift, 'Noto Sans HK', sans-serif",
  },
  palette: {
    primary: {
      main: teal[400],
    },
    success: {
      main: green[600],
    },
    error: {
      main: red[600],
    },
  },
});

const App = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {!isLogginIn() ? <AuthNavigator /> : <MainNavigator />}
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
