import React, { Suspense, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorFallback";

const CustomFallback = () => {
  const { appLoading } = useOutletContext();
  const { setAppLoadingText } = appLoading;

  useEffect(() => {
    setAppLoadingText("Redirecting");
  }, []);

  return <></>;
};
// error boundary and suspense for routing
const ErrorSuspense = ({ view }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<CustomFallback />}>{view}</Suspense>
    </ErrorBoundary>
  );
};

export default ErrorSuspense;
