import SettingsIcon from "@mui/icons-material/Settings";
import DevicesIcon from "@mui/icons-material/Devices";
import GroupIcon from "@mui/icons-material/Group";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HandshakeIcon from "@mui/icons-material/Handshake";

const getCategoryIcon = (form_category_seq) => {
  switch (form_category_seq) {
    case 1:
      return <SettingsIcon color="primary" />
    case 2:
      return <DevicesIcon color="primary" />
    case 3:
      return <GroupIcon color="primary" />
    case 4:
      return <EngineeringIcon color="primary" />
    case 5:
      return <AccountBalanceIcon color="primary" />
    case 6:
      return <HandshakeIcon color="primary" />
    default:
      return
  }
}

const getDataSyncSectionName = (form_code) => {
  switch (form_code) {
    case "PTC":
      return "Oracle AP";
    default:
      return "Data Sync";
  }
}

const getCreateRoute = (form_type_seq) => {
  return "/form/" + form_type_seq + "/create";
}

const getSyncRoute = (form_type_seq) => {
  return "/form/" + form_type_seq + "/data_sync";
}

const getAdminRoute = (form_type_seq) => {
  return "/form/" + form_type_seq + "/admin";
}

export {
  getCategoryIcon,
  getDataSyncSectionName,
  getCreateRoute,
  getSyncRoute,
  getAdminRoute,
};
