import axios from "axios";

const oidcSignIn = async (searchParamString) => {
  const signIn_api = "/login/gcl_oidc/callback";
  const params = new URLSearchParams(searchParamString);
  try {
    // params.append("code", code);
    // params.append("state", state);
    // params.append("redirect_uri", process.env.REACT_APP_OIDC_REDIRECT_URL);

    const result = await axios({
      method: "get",
      url: process.env.REACT_APP_OIDC_API + signIn_api,
      params: params,
    });

    return result.data;
  } catch (error) {
    throw new Error(error);
  }
};

// const oidcSignOut = async () => {
// 	const signOut_api = "/logout"
// 	// let params = new URLSearchParams()

// 	try {
// 		// params.append("code", code)
// 		// params.append("state", state)

// 		const result = await axios({
// 			method: "get",
// 			url: process.env.REACT_APP_USERSERVICE_API_URL + signOut_api,
// 			// params: params,
// 		})

// 		return result.data
// 	}
// 	catch (error) {
// 		throw new Error(error)
// 	}
// }

const getUserData = async (token) => {
  const getUserData_api = "/login";
  try {
    let headers = {
      Authorization: token,
    };

    const result = await axios({
      method: "get",
      url: process.env.REACT_APP_OIDC_API + getUserData_api,
      headers: headers,
    });

    return result.data;
  } catch (error) {
    throw new Error(error);
  }
};

export { oidcSignIn, getUserData };
