import React, { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ErrorSuspense from "../components/ErrorSuspense";
import ValidateRoute from "./ValidateRoute";
import OidcCallback from "../components/SignIn/OidcCallback";
import MainView from "../views/Main/MainView";

// import DashboardView from "../views/Dashboard/DashboardView";
// import NewFormView from "../views/NewForm/NewFormView";
// import ExistFormView from "../views/ExistForm/ExistFormView";
// import NotFoundView from "../views/NotFound/NotFoundView";
const DashboardView = lazy(() => import("../views/Dashboard/DashboardView"));
const NewFormView = lazy(() => import("../views/NewForm/NewFormView"));
const ExistFormView = lazy(() => import("../views/ExistForm/ExistFormView"));
const NotFoundView = lazy(() => import("../views/NotFound/NotFoundView"));
const DataSyncView = lazy(() => import("../views/DataSync/DataSyncView"));
// const FormAdminView = lazy(() => import("../views/FormAdmin/FormAdminView"));

const MainNavigator = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/gcl_oidc_callback" element={<OidcCallback />} />
        <Route path="/" element={<MainView />}>
          <Route
            path="/dashboard"
            element={
              <ErrorSuspense
                view={
                  <ValidateRoute.Dashboard>
                    <DashboardView />
                  </ValidateRoute.Dashboard>
                }
              />
            }
          />
          <Route
            path="/form/:form_type_seq/create"
            element={
              <ErrorSuspense
                view={
                  <ValidateRoute.NewForm>
                    <NewFormView />
                  </ValidateRoute.NewForm>
                }
              />
            }
          />
          <Route
            path="/form/:form_type_seq/:form_seq"
            element={
              <ErrorSuspense
                view={
                  <ValidateRoute.ExistForm>
                    <ExistFormView />
                  </ValidateRoute.ExistForm>
                }
              />
            }
          />
          <Route
            path="/form/:form_type_seq/data_sync"
            element={
              <ErrorSuspense
                view={
                  <ValidateRoute.DataSync>
                    <DataSyncView />
                  </ValidateRoute.DataSync>
                }
              />
            }
          />

          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route
            path="*"
            element={
              <ErrorSuspense
                view={
                  <ValidateRoute.NotFound>
                    <NotFoundView />
                  </ValidateRoute.NotFound>
                }
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default MainNavigator;
