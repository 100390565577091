import React from "react";

import {BrowserRouter, Route, Routes} from "react-router-dom";

// Custom Components
import SignIn from "../components/SignIn/SignIn";
import OidcCallback from "../components/SignIn/OidcCallback";

const AuthNavigator = () => {
    return (<BrowserRouter>
            <Routes>
                <Route path="/gcl_oidc_callback" element={<OidcCallback/>}/>
                <Route path="*" element={<SignIn/>}/>
            </Routes>
        </BrowserRouter>);
};

export default AuthNavigator;
